import React from "react";
import { Link } from "react-router-dom";
import { price } from "../../dummydata"; // Assuming price array is imported correctly
import "./PriceCard.css";

const PriceCard = () => {
  return (
    <>
      {price.map((val, index) => (
        <div className="items shadow description" key={index}>
          <h1>{val.price}</h1>

          {/* Conditional rendering based on the id */}
          {(val.id === 1 || val.id === 2 || val.id === 3 || val.id === 4 || 
            val.id === 5 || val.id === 6 || val.id === 10) ? (
            <span>Rozsah programu: 20 hodín/1 účastník</span>
          ) : (
            <span>Rozsah programu: 16 hodín/1 účastník</span>
          )}
          
          <br />
          <span>Nie sme platcom DPH</span>

          <Link
            to={{
              pathname: "/contact",
              state: { selectedOption: val.desc }, // Pass the selected option as state
            }}
          >
            <button className="outline-btn">Objednať</button>
          </Link>
          <p className="description">{val.desc}</p>
          
        </div>
      ))}
    </>
  );
};

export default PriceCard;



