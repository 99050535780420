import React from "react"
import { blog } from "../../../dummydata"
import "./footer.css"

const Footer = () => {
  return (
    <>
      
      <footer>
        <div className='container padding'>
          <div className='box logo'>
           
            
          </div>
          <div className='box link'>
          
             <h1>MASTEL CONSULTING</h1>
            <span>KOMPLEXNÉ VZDELÁVANIE PRE KVALITNÚ STAROSTLIVOSŤ</span>

          </div>
         
          <div className='box'>
            <h3></h3>
          
              <div className='items flexSB'>
            
                </div>
         
    
          </div>
          <div className='box '>
            <h3>Máte otázku?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                 Nová Kelča č. 423, 094 04 Nová Kelča
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                0917 911 058
              </li>
              <li>
             <a href="mailto:oazacharbela@gmail.com" style={{ color: 'black', textDecoration: 'none' }}>
             <i className="fa fa-paper-plane box"></i>
                mastel1316@gmail.com
             </a>
            </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
           ©2024 All rights reserved | This template is made with <i className='fa fa-heart'></i> Love
        </p>
        <p> Compiled by NOON</p>
      </div>
    </>
  )
}

export default Footer
