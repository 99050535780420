import React, { useState } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import { motion, AnimatePresence } from 'framer-motion';
import useWeb3Forms from '@web3forms/react';
import email from '../../images/email.png';




export const price = [
  {
    name: "Syndróm vyhorenia zamestnancov v školských zariadeniach: príčiny, dôsledky, sociálno-patologické javy, šikana, kyberšikana, mobbing, bossing, možnosti prevencie a prístupov k ohrozeným žiakom.",
  
    desc: "Syndróm vyhorenia zamestnancov v sociálnych službách: stimuly vzniku, prevencia, psychohygiena, zvládanie záťažových situácií zamestnancov.",
  },
  {
    name: "Syndróm vyhorenia zamestnancov v sociálnych službách: stimuly vzniku, prevencia, psychohygiena, zvládanie záťažových situácií zamestnancov.",

    desc: "Syndróm vyhorenia zamestnancov v sociálnych službách: stimuly vzniku, prevencia, psychohygiena, zvládanie záťažových situácií zamestnancov.",
  },
  {
    name: "Ľudské práva v sociálnych službách zamerané na kvalitu: dodržiavanie, kontext, prevencia poškodenia prijímateľa.",

    desc: "Ľudské práva v sociálnych službách zamerané na kvalitu: dodržiavanie, kontext, prevencia poškodenia prijímateľa.",
  },
  {
    name: "Spolupráca subjektov v rámci paliatívnej starostlivosti zameraná na prijímateľov sociálnych služieb.",
   
    desc: "Spolupráca subjektov v rámci paliatívnej starostlivosti zameraná na prijímateľov sociálnych služieb.",
  },
  {
    name: "Podmienky zabezpečovania sociálnych služieb v systéme samosprávy.",

    desc: "Podmienky zabezpečovania sociálnych služieb v systéme samosprávy.",
  },
   {
    name: "Sociálna pomoc ohrozeným rodinám v kooperácii subjektov a význam preventívnych aktivít sociálno-patologických javov s možnosťami ich realizácie v lokalite v kontexte syndrómu vyhorenia zamestnancov miestnej samosprávy..",

    desc: "Sociálna pomoc ohrozeným rodinám v kooperácii subjektov a význam preventívnych aktivít sociálno-patologických javov s možnosťami ich realizácie v lokalite v kontexte syndrómu vyhorenia zamestnancov miestnej samosprávy..",
  },
   {
    name: "Kvalita v sociálnych službách: Etické problémy, dilemy v sociálnej práci, dodržiavanie a rešpektovanie ľudských práv a slobôd zamestnancov pri poskytovaní sociálnych služieb.",

    desc: "Kvalita v sociálnych službách: Etické problémy, dilemy v sociálnej práci, dodržiavanie a rešpektovanie ľudských práv a slobôd zamestnancov pri poskytovaní sociálnych služieb.",
  },
    {
    name: "Komunikácia na pracovisku, zvládanie náročných situácií v kolegiálnych vzťahoch, riešenie konfliktov spojené s interpersonálnymi zručnosťami a syndróm vyhorenia.",

    desc: "Komunikácia na pracovisku, zvládanie náročných situácií v kolegiálnych vzťahoch, riešenie konfliktov spojené s interpersonálnymi zručnosťami a syndróm vyhorenia.",
  },
     {
    name: "Syndróm vyhorenia profesionálov pracujúcich s ohrozenými deťmi, prevencia, zvládanie náročných výchovných situácií a možnosti aktivizácie ohrozených detí prostredníctvom záujmových činností s prvkami expresívnych terapií.",

    desc: "Syndróm vyhorenia profesionálov pracujúcich s ohrozenými deťmi, prevencia, zvládanie náročných výchovných situácií a možnosti aktivizácie ohrozených detí prostredníctvom záujmových činností s prvkami expresívnych terapií.",
  },
    {
    name: "Syndróm vyhorenia zamestnancov v sociálnych službách a školských zariadeniach: príčiny, dôsledky, sociálno-patologické javy, možnosti prevencie a prístupov k ohrozeným žiakom a seniorom.",

    desc: "Syndróm vyhorenia zamestnancov v sociálnych službách a školských zariadeniach: príčiny, dôsledky, sociálno-patologické javy, možnosti prevencie a prístupov k ohrozeným žiakom a seniorom.",
  },

];

const Contact = () => {
  const [formData, setFormData] = useState({
    meno: '',
    email: '',
    sprava1: '', 
    sprava2: '',
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accessKey = '806c5170-9a35-46a4-a1af-63ca3af3639e'
  const { submit, isLoading, isError } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: 'MASTEL consulting - kontaktný formulár',  

      subject: 'Nová správa zo stránky MASTEL consulting',
    },
    onSuccess: (message, responseData) => {
      console.log(message);
      setIsModalOpen(true);
    },
    onError: (message, errorData) => {
      console.log(message);
      setIsModalOpen(true);
    },
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.value;
    const selectedDescription = price[selectedIndex].desc;
    setSelectedOption(selectedIndex);
    setFormData({ ...formData, sprava1: selectedDescription });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submit(formData);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Back title='Kontakt' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
       
          <div className='right row'>
            <h1>Kontakt</h1>
            <p>Sme otvorení akýmkoľvek návrhom alebo len na rozhovor.</p>
            <div className='items grid2'>
              <div className='box'>
                <h4>Firma:</h4>
                <p>MASTEL consulting s.r.o.</p>
                <p>IČO: 56444125</p>
                <p>DIČ: 2122307242</p>
                <p>Nová Kelča 423 094 04</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p>mastel1316@gmail.com</p>
                <h4>Telefón:</h4>
                <p>0917 911 058</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className='flexSB'>
                <input
                  type='text'
                  placeholder='Meno'
                  name='meno'
                  value={formData.meno}
                  onChange={handleChange}
                  required
                />
                <input
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div> 
                <label className="priceOptions" htmlFor="priceOptions">Vyberte možnosť vzdelávacieho programu</label>
                <select className="select"id="priceOptions" value={selectedOption} onChange={handleSelectChange}>
                  <option className="input"value="" disabled>Vyberte možnosť:</option>
                  {price.map((option, index) => (
                    <option key={index} value={index}>
                      {option.name} {option.price}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                name="sprava1"
                value={formData.sprava1}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <textarea
                cols='30'
                rows='10'
                name="sprava2"
                value={formData.sprava2}
                onChange={handleChange}
                placeholder="Sem napíšte správu"
              />
              <button type='submit' className='primary-btn' disabled={isLoading}>
                {isLoading ? 'Odosielam formulár...' : isError ? 'Error submitting form' : 'Odoslať formulár'}
              </button>
            </form>

            <AnimatePresence>
  {isModalOpen && (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        style={{
          background: 'white',
          padding: 20,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
<p>

        </p>        <p>Email úspešne odoslaný! Odpovieme na váš email, keď to bude možné.</p>

        <button
          type="submit"
          className='items-center justify-center'
          onClick={closeModal}
          style={{
            backgroundColor: 'var(--secondary-color)',
            padding: '1rem 1rem', 
            borderRadius: '10px', 
            border: 'none', 
            fontWeight: 500,
            color: 'var(--white-color)',
            outline: 'none',
            margin: '2rem 0 0 0',
            fontFamily: 'var(--font-base)',
            transition: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
            cursor: 'pointer',
          }}
        >
          Zatvoriť okno
        </button>
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
